const Config = {
    backendUrl:"https://login.isagzth.com",
    resAddr:"https://media2.isagzth.com/parent-weapp/projection/",
	BlessingColor:[
		{'background-color':'rgba(122, 174, 211,1)','color':'#FFFFFF'},
		{'background-color':'rgba(188, 193, 203,1)','color':'#FFFFFF'},
		{'background-color':'rgba(111, 125, 139,1)','color':'#FFFFFF'},
		{'background-color':'rgba(164, 143, 125,1)','color':'#FFFFFF'},
		{'background-color':'rgba(211, 172, 133,1)','color':'#FFFFFF'},
		{'background-color':'rgba(231, 171, 130,1)','color':'#FFFFFF'},
		{'background-color':'rgba(155, 206, 160,1)','color':'#FFFFFF'},
		{'background-color':'rgba(156, 62, 54,1)','color':'#FFFFFF'},
		{'background-color':'rgba(221, 202, 176,1)','color':'#FFFFFF'},
		{'background-color':'rgba(229, 240, 227,1)','color':'#5E5E5E'}
	],
}

export default Config;