import config from './Config';
import { get } from './serviceUtil';
export default class Common{
    constructor(){
        this.resAddr = config.resAddr;
    }
    
    //获取静态资源
    getRes(name){
        return this.resAddr+name;
    }
    async refreshActivity(){
        var activity = this.getLocal("activity");
        if(activity){
           var res = await get(`/activity/program/from-code/?activity_id=${activity.activity_id}`);
           if(res.status == 200){
               this.setLocal("activity",res.data);
               return res.data;
           }
        }
    }
    setLocal(name,value){
        localStorage.setItem(name,JSON.stringify(value));
    }
    getLocal(name,def){
        var value = JSON.parse(localStorage.getItem(name));
        if(value != null){
            return value;
        }else{
            this.setLocal(name,def);
            return def;
        }
    }
    removeLocal(name){
        localStorage.removeItem(name);
    }

    //隐藏电话号码
    highTelphoneNum(telphoneNum){
        return telphoneNum.substr(0,telphoneNum.length-1)+"*";
        //const head = telphoneNum.substr(0,4);
        //const tail = telphoneNum.substr(telphoneNum.length-3,3);
        //return `${head}****${tail}`;
    }
    highTelphoneName(name){
        return name.length>4 ? name.substr(0,6)+"***" : name.substr(0,1)+"**";
    }
}